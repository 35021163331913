import { AcpConnectedBanksSectionComponentCtrl } from './index';

/**
 * This route is dynamically added to the app if this section is
 * enabled inside the config for the tenant being built.
 */
export function acpConnectedBanksStates($stateProvider) {
  'ngInject';

  $stateProvider
    .state('connected-banks', {
      parent: 'root',
      url: '/connected-banks?oauth_state_id',
      controller: AcpConnectedBanksSectionComponentCtrl,
      template: '<acp-connected-banks-section></acp-connected-banks-section>',
      data: {
        permissions: {
          only: 'isConnectedBanksVisible'
        }
      },
      resolve: {
        module($ocLazyLoad) {
          'ngInject';

          return import(
            /* webpackChunkName: "acp.section.connected-banks" */ './index'
          ).then($ocLazyLoad.loadModule);
        }
      }
    })
    // Default error page AKA "Something Went Wrong"
    .state('connected-banks.error', {
      parent: 'root',
      url: '/connected-banks/error',
      template: '<acp-connected-banks-error></acp-connected-banks-error>'
    })
    // Error page specific to bank linking failures
    .state('connected-banks.link-error', {
      parent: 'root',
      url: '/connected-banks/link-error',
      template:
        '<acp-connected-banks-link-error></acp-connected-banks-link-error>'
    });
}
